import React, { useCallback, useMemo, useContext } from "react";
import lang from "translations";
import { Path } from "paths";
import { HeaderB } from "components/headers";
import { FragmentA, ModuleWrapper } from "components/fragments";
import { VenueContext } from "contexts";
import { getPaymentGatewaySetting } from "apis/payment-gateway.api";
import { useApi, useMount, useRouter } from "hooks";
import { Field } from "components/commons";
import { Panel, Skeleton } from "components/index";
import Input from "components/commons/input/input";
import { ActionButton } from "components/index";

const PaymentGatewaySettingView = () => {
  const { query, history } = useRouter();
  const { id } = query;

  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const {
    request: getPaymentGatewaySettingDetails,
    loading,
    mappedData,
  } = useApi({
    api: getPaymentGatewaySetting,
    mapper: {
      _keys: ["id", "merchantId", "displayName", "isDefault", "merchantSecret", "merchantUrl"],
    },
    params: {
      venueId
    },
  });

  useMount(() => {
    fetchDetails();
  });

  const fetchDetails = useCallback(async () => {
    await getPaymentGatewaySettingDetails({ id });
  }, [getPaymentGatewaySettingDetails, id]);

  const initialState = useMemo(() => {
    const { displayName, merchantUrl, merchantId, merchantSecret, isDefault } = mappedData;
    return {
      displayName,
      merchantUrl,
      merchantId,
      merchantSecret,
      isDefault,
    };
  }, [mappedData]);

  const goToList = useCallback(() => {
    history.push(Path.PAYMENT_GATEWAY_SETTING);
  }, [history]);

  return (
    <ModuleWrapper
      header={
        <HeaderB
          returnText={lang.paymentsSettings}
          title={lang.viewPaymentGateway}
          returnPath={Path.PAYMENT_GATEWAY_SETTING}
          onClick={() => {}}
        />
      }
    >
      <FragmentA>
        <Panel>
          {loading ? (
            <Skeleton />
          ) : (
            <div>
              <Field label={lang.displayName}>
                <Input value={initialState.displayName || lang.notAvailable} disabled />
              </Field>
              <Field label={lang.merchantUrl}>
                <Input value={initialState.merchantUrl || lang.notAvailable} disabled />
              </Field>
              <Field label={lang.merchantId}>
                <Input value={initialState.merchantId || lang.notAvailable} disabled />
              </Field>
              <Field label={lang.merchantSecret}>
                <Input
                  inputType="password"
                  value={initialState.merchantSecret || lang.notAvailable}
                  disabled
                />
              </Field>
              <Field label={lang.isDefaultPaymentGateway}>
                <Input value={initialState.isDefault ? lang.yes : lang.no} disabled />
              </Field>
            </div>
          )}
        </Panel>
      </FragmentA>

      <ActionButton
        showLine
        primary={{
          text: "Back",
          onClick: () => {
            goToList();
          },
        }}
        secondary={{
          onClick: () => {
            goToList();
          },
        }}
      />
    </ModuleWrapper>
  );
};

export default PaymentGatewaySettingView;
