export const settingPaths = {
  SETTING_GENERAL_SETTING: "general",
  SETTING_TAX: "tax",
  SETTING_ADDITIONAL_CHARGE: "additional-charge",
  SETTING_ADDITIONAL_CHARGE_EDIT: "additional-charge/edit/:id",
  SETTING_ADDITIONAL_CHARGE_EDIT_ID: (id) => `additional-charge/edit/${id}`,
  SETTING_ADDITIONAL_CHARGE_ADD: "additional-charge/add",
  PAYMENT_METHOD_SETTING: "payment-method",
  CREDIT_LIMIT_SETTING: "credit-settings",
  ACCOUNT_SETTING: "account",
  QUESTION_SETTING: "question",
  WRISTBAND_KEEPING: "wristband-keeping",
  BAN_TAG: "ban-tag",
  SETTING_NOTIFICATION: "notifications",
  SETTING_NOTIFICATION_STOCK_LEVEL: "notification/stock-level",
  RECEIPT_SETTING: "receipt",
  INTEGRATION_SETTING: "integration",
  ACCOUNT_MAPPING: "account-mapping",
  CHART_OF_ACCOUNTS: "chart-of-accounts",
  SETTING_TIP: "tip",
  SETTING_ACCESS: "access",
  PAYMENT_GATEWAY_SETTING: "payment-gateway-setting",
  PAYMENT_GATEWAY_SETTING_EDIT: "payment-gateway-setting/edit/:id",
  PAYMENT_GATEWAY_SETTING_EDIT_ID: (id) => `payment-gateway-setting/edit/${id}`,
  PAYMENT_GATEWAY_SETTING_ADD: "payment-gateway-setting/add",
  PAYMENT_GATEWAY_SETTING_VIEW: "payment-gateway-setting/view/:id",
  PAYMENT_GATEWAY_SETTING_VIEW_ID: (id) => `payment-gateway-setting/view/${id}`,
};
