import React, { useCallback, useMemo, useContext } from "react";
import lang from "translations";
import { Path } from "paths";
import { HeaderB } from "components/headers";
import { FragmentA, ModuleWrapper } from "components/fragments";
import { VenueContext } from "contexts";
import { editAdditionalCharge, queryAdditionalCharge } from "apis/additional-charge.api";
import { useApi, useModal, useMount, useRouter } from "hooks";
import { Text, Toast, Switch } from "components/commons";
import { mixpanel, TrackEvent } from "mixpanel";
import { additionalChargeResponseMapper } from "mappers/additional-charge.mapper";
import { Form, Field, RadioGroup, Checkbox, InputMoney } from "components/commons/index";
import { ActionButton, MultipleSelect, Panel, Skeleton } from "components/index";
import initialFormState from "../form/additional-charge.form-state";
import useForm from "hooks/useForm";
import Input from "components/commons/input/input";
import { getLocations } from "apis/location.api";
import { location } from "mappers";
import { mapObjectsToSelect } from "services";
import { isNumberValid, isValidDecimalPlaces, parseAmountToNumber } from "services";
import DeleteAdditionalCharge from "./additional-charge-delete-modal";

const AdditionalChargeSetting = () => {
  const { query, history } = useRouter();
  const { id } = query;

  const deleteModal = useModal();
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const {
    request: getAdditionalChargeDetails,
    loading = true,
    mappedData,
  } = useApi({
    api: queryAdditionalCharge,
    mapper: additionalChargeResponseMapper,
  });

  const unsaveChangesModal = useModal();
  useMount(() => {
    fetchDetails();
    requestLocations({ venueId });
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editCharge,
    });
  });

  const {
    request: requestLocations,
    loading: loadingLocations,
    mappedData: locations,
  } = useApi({
    api: getLocations,
    isArray: true,
    mapper: location,
  });

  const fetchDetails = useCallback(async () => {
    await getAdditionalChargeDetails({ id });
  }, [getAdditionalChargeDetails, id]);

  const initialState = useMemo(() => {
    const {
      name,
      value,
      type,
      locationIds,
      isActiveAllLocations,
      showInPos,
      addToCart,
      calculateMethod,
    } = mappedData;
    return {
      name,
      locationIds,
      isActiveAllLocations,
      calculateMethod,
      type,
      showInPos,
      addToCart,
      value,
    };
  }, [mappedData]);

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { fields, modifyField, getFormValues, dirty, submitForm } = useForm({
    initialState: formState,
  });

  const goToList = useCallback(() => {
    history.push(Path.SETTING_ADDITIONAL_CHARGE);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const calculateMethodOptions = useMemo(() => {
    return [
      // {
      //   text: "Calculate charge before tax",
      //   value: "WITHOUT_TAX",
      // },
      {
        text: "Calculate charge after tax",
        value: "WITH_TAX",
      },
    ];
  }, []);

  const typeOptions = useMemo(() => {
    return [
      {
        text: "Percentage",
        value: "PERCENTAGE",
      },
      {
        text: "Fixed",
        value: "FIXED",
      },
    ];
  }, []);

  const locationOptions = useMemo(() => {
    let sorted = locations
      .map((item) => {
        item.name = item.name.trim();
        return item;
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    let mapped = mapObjectsToSelect(sorted, { textKey: "name", valueKey: "id" });
    return mapped;
  }, [locations]);

  //submit api

  const { request: editAdditionalChargeRequest, loading: submitting } = useApi({
    api: editAdditionalCharge,
    handleOwnError: {
      badrequest: true,
    },
  });

  const submitUpdate = useCallback(async () => {
    try {
      await editAdditionalChargeRequest({
        ...getFormValues(),
        id,
        locationIds: fields.locationIds.value.filter((x) => !!x).map((x) => x.value),
        isActiveAllLocations: fields.locationIds.value.length === 0,
        venueId: venueId,
      });
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
    } catch (error) {
      console.log(error);
    }
  }, [getFormValues, editAdditionalChargeRequest, id, fields.locationIds, venueId]);

  const isMoneyValid = useCallback((value) => {
    value = parseAmountToNumber(value) || 0;
    return (
      ((Number(value) || value === "0" || !value) && Number(value) < 99999999) ||
      (value && value.toString().indexOf(".") === value.length - 1)
    );
  }, []);

  return (
    <ModuleWrapper
      header={
        <HeaderB
          returnText={lang.additionalCharges}
          title={lang.editCharge}
          returnPath={Path.SETTING_ADDITIONAL_CHARGE}
          onClick={() => {}}
        />
      }
    >
      <Form
        unsaveChangesModal={unsaveChangesModal}
        isPrompt={dirty}
        submitForm={() => submitForm(submitUpdate)}
      >
        <FragmentA>
          <Panel>
            {loading ? (
              <Skeleton />
            ) : (
              <div>
                <div className="flex flex-row items-center mb-5 ">
                  <Switch {...fields.showInPos} onChange={modifyField} />

                  <Text
                    className="ml-sm"
                    size="text-sm"
                    color="text-gray"
                    fontWeight="font-semibold"
                  >
                    {lang.activateOnPOS}
                  </Text>
                </div>
                <Field label={lang.chargeName} {...fields.name}>
                  <Input required {...fields.name} onChange={modifyField} />
                </Field>

                <Field {...fields.type} label={lang.type}>
                  <RadioGroup
                    // title={fields.type.name}
                    {...fields.type}
                    options={typeOptions}
                    onChange={(value) => {
                      modifyField("type", { value: value });
                    }}
                  />
                </Field>

                <div className="grid grid-cols-5 my-md">
                  <Field label={lang.value} {...fields.value}>
                    {fields.type.value === "FIXED" ? (
                      <InputMoney
                        placeholder="0.00"
                        {...fields.value}
                        onChange={(obj, value) => {
                          let val = (value.value + "").trim();
                          if (isMoneyValid(val)) {
                            modifyField(obj, { value: val });
                          }
                        }}
                      />
                    ) : (
                      <Input
                        required
                        {...fields.value}
                        onChange={(name, obj) => {
                          const { value } = obj;
                          if (!isNumberValid(value) || !isValidDecimalPlaces(value, 3)) {
                            return;
                          }

                          const max = 9999999.999;
                          if (parseAmountToNumber(value) <= max) {
                            // supplyQuantity.onChange(name, {
                            //   value: value.trim(),
                            // });
                            modifyField("value", { value: obj.value });
                          }
                        }}
                        iconSuffix={<span className="text-sm text-gray-400 pl-sm">%</span>}
                      />
                    )}
                  </Field>
                </div>
                <Field {...fields.calculateMethod} label={lang.chargeCalculation}>
                  <RadioGroup
                    {...fields.calculateMethod}
                    options={calculateMethodOptions}
                    onChange={(value) => {
                      modifyField("calculateMethod", { value: value });
                    }}
                  />
                </Field>

                <Field label={lang.location} {...fields.locationIds}>
                  <MultipleSelect
                    {...fields.locationIds}
                    options={locationOptions}
                    optionsLoading={loadingLocations}
                    isAll={fields.isActiveAllLocations}
                    onChange={(name, obj) => {
                      modifyField("locationIds", { value: obj.value });
                    }}
                  />
                </Field>

                <div className="flex flex-row">
                  <Checkbox
                    {...fields.addToCart}
                    // className={classnames("m-auto")}
                    onChange={(name, obj) => {
                      modifyField("addToCart", { value: obj.value });
                    }}
                  />

                  <div>
                    <Text className="ml-sm">{lang.automaticallyAddThisChargeToTheCart}</Text>
                    <Text className="ml-sm" size="text-xs" italic={true}>
                      {lang.automaticallyAddThisChargeToTheCartDesc}
                    </Text>
                  </div>
                </div>
              </div>
            )}
          </Panel>
        </FragmentA>

        <ActionButton
          showLine
          loading={submitting}
          primary={{
            disabled: fields.locationIds?.length === 0 && !fields.isActiveAllLocations,
            onClick: () => {
              submitForm(submitUpdate);
            },
          }}
          secondary={{
            text: lang.cancel,
            onClick: () => leavePage(),
          }}
          danger={{
            text: lang.deleteCharge,
            onClick: () => deleteModal.show(),
          }}
        />
        <DeleteAdditionalCharge id={id} {...deleteModal} />
      </Form>
    </ModuleWrapper>
  );
};

export default AdditionalChargeSetting;
