import { deletePaymentGateway, getPaymentGatewaySetting } from "apis/payment-gateway.api";
import { Text, Title, Toast, Icon } from "components/commons";
import { DeleteModal } from "components/modals";
import { useApi, useMount, useRouter } from "hooks";
import { Path } from "paths";
import React, { useCallback, useEffect } from "react";
import lang from "translations";
import { mixpanel, TrackEvent } from "mixpanel";

const DeletePaymentGatewaySetting = ({ refreshList, id = 0, ...props }) => {
  const { history } = useRouter();

  const { request } = useApi({
    api: deletePaymentGateway,
    params: {
      id,
    },
  });

  const {
    request: requestPaymentGatewaySetting,
    loading = true,
    mappedData,
  } = useApi({
    api: getPaymentGatewaySetting,
    params: {
      id,
    },
    handleOwnError: {
      badrequest: true,
    },
    mapper: {
      _keys: ["id", "displayName"],
    },
  });

  useMount(() => {
    fetchPaymentGatewaySetting();
  });

  const fetchPaymentGatewaySetting = useCallback(async () => {
    await requestPaymentGatewaySetting();
  }, [requestPaymentGatewaySetting]);

  useEffect(() => {
    fetchPaymentGatewaySetting();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {!loading ? (
        <DeleteModal
          primaryText={lang.deletePaymentGateway}
          customHeader={
            <div className="flex items-center justify-between">
              <Title lg className="mb-sm pt-md">
                {lang.deletePaymentGateway + "?"}
              </Title>
              <Icon
                className="outline-none text-xl text-gray hover:text-gray cursor-pointer"
                name="remove"
                onClick={() => {
                  props.close();
                }}
              />
            </div>
          }
          customContent={
            <Text>
              {lang.populate(lang.areYouSureYouWantToDeletePaymentGatewaySetting, [
                mappedData.displayName,
              ])}
            </Text>
          }
          ok={async () => {
            mixpanel.track(TrackEvent.ClickedButton, {
              Button: lang.deletePaymentGateway,
              Page: lang.supplyItems,
            });

            await request();
            Toast({
              content: lang.populate(lang.paymentGatewayDeleted, [mappedData.displayName]),
              success: true,
              icon: "check",
            }).open();
            props.close();
            history.push(Path.PAYMENT_GATEWAY_SETTING);
            if (refreshList) {
              refreshList();
            }
          }}
          {...props}
        />
      ) : null}
    </>
  );
};

export default DeletePaymentGatewaySetting;
