import { getListInvoice, resyncInvoiceApi, sendToInvoicesApi } from "apis/siigo.api";
import {
  Button,
  DataTable,
  // Dropdown,
  HeaderA,
  Icon,
  ModuleWrapper,
  Text,
  Toast,
} from "components";
import { AppContext, VenueContext } from "contexts";
import { useApi, useFilter, useModal, useMount, useRouter, useSelectItems } from "hooks";
import React, { useCallback, useContext, useMemo, useState } from "react";
import lang from "translations";
import InvoiceFilter from "./InvoiceFilter";
import moment from "moment";
import { InvoicedSiigo } from "enums";
import { prettifyInvoiced } from "services/pretty.service";
import classNames from "classnames";
import CopyIcon from "assets/svg/Copy";
import { Path } from "paths";
import { Tooltip } from "antd";
import styles from "./invoices.css";
import { listInvoiceMapper } from "mappers/siigo.mapper";
import SendToModal from "../send-to-modal";
import useFilterStore, { filterName } from "hooks/filterStore";
import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "lodash";
import { pluralize } from "services";

const InvoicesModule = () => {
  const { venue } = useContext(VenueContext);
  const { setGlobalLoading } = useContext(AppContext);
  const { venueId } = venue;
  const { history } = useRouter();
  const sendToModal = useModal();
  const [loadingSendSiigo, setLoadingSendSiigo] = useState(false);
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    request,
    error,
    loading,
    mappedData,
    result = { data: [] },
  } = useApi({
    api: getListInvoice,
    isArray: true,
    mapper: listInvoiceMapper,
  });

  const { modifyFilter, modifyFilters, clearFilter, filterState, requestState } = useFilter(
    filterName.eInvoiced === getState().name && !isEmpty(getState().filter)
      ? getState().filter
      : getInitFilterState(venueId),
    getInitFilterState(venueId)
  );

  const {
    selected,
    setSelected,
    isAllSelected,
    setSelectAll,
    clearSelected,
    /* replaceSelected,*/
    denormalizeItems,
  } = useSelectItems({
    items: mappedData,
    indeterminate: true,
  });
  // const selectID = useSelectID("siigo-invoice");

  const fetchList = useCallback(
    async (requestState, filterState) => {
      const params = { ...requestState };
      params.integration = "SIIGO";
      params.fromIssuedDate = moment(params.dateRange[0]).format("YYYY-MM-DD");
      params.toIssuedDate = moment(params.dateRange[1]).format("YYYY-MM-DD");
      params.pageNumber = params.page - 1;

      delete params.page;
      delete params.locationState;
      delete params.dateRange;

      setFilterState({
        name: filterName.eInvoiced,
        filter: filterState,
      });
      clearSelected();
      const res = await request(params);
      return res;
    },
    [clearSelected, request, setFilterState]
  );

  const applyFilterCb = useCallback(
    (searchKey) => {
      const { requestState, filterState } = modifyFilters({ page: 1, searchKey });
      fetchList(requestState, filterState);
    },
    [fetchList, modifyFilters]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState, filterState } = clearFilter();
    fetchList(requestState, filterState);
  }, [clearFilter, fetchList]);

  const onSelect = (id) => {
    setSelected(id);
    // selectID.onSel(id);
  };
  const onSelectAll = () => {
    setSelectAll();
    // const ids = mappedData?.map(({ id }) => id);
    // if (!isAllSelected) selectID.onAdd(ids);
    // else selectID.onSub(ids);
  };

  const sendToAction = useCallback(
    async (ids, toDian = false) => {
      const data = ids.map((i) => ({
        invoiceId: i,
        synced: !toDian,
        syncedDian: toDian,
      }));
      try {
        setLoadingSendSiigo(true);
        await sendToInvoicesApi(data);
        Toast({
          content: toDian ? lang.sendToDianSuccessfully : lang.sendToSiigoSuccessfully,
          success: true,
          icon: "check",
        }).open();
      } catch (error) {
        Toast({
          //content: error.messages[0] || lang.somethingWentWrong,
          content: lang.siigoSendInvoiceError || lang.somethingWentWrong,
          error: true,
          icon: "exclamation-fill",
        }).open();
      } finally {
        await fetchList(requestState, filterState);
        setLoadingSendSiigo(false);
      }
    },
    [fetchList, filterState, requestState]
  );

  const onResync = async (ids) => {
    try {
      setGlobalLoading(true);
      const data = ids.map((i) => ({
        invoiceId: i,
        synced: true,
      }));
      await resyncInvoiceApi(data);
      Toast({ content: lang.resyncSuccessfully, success: true, icon: "check" }).open();
    } catch (error) {
      Toast({ content: lang.somethingWentWrong, error: true, icon: "exclamation-fill" }).open();
    } finally {
      await fetchList(requestState, filterState);
      setGlobalLoading(false);
    }
  };

  const enableSync = useMemo(() => {
    const arr = Object.values(selected);
    if (!arr.length) return false;
    for (let i = 0; i < arr.length; i++) {
      const el = arr[i];
      if (el.warningResync && InvoicedSiigo.Unbill === el.state) {
        continue;
      } else return false;
    }
    return true;
  }, [selected]);

  const enableSendSiigo = useMemo(() => {
    const arr = Object.values(selected);
    if (!arr.length) return false;
    for (let i = 0; i < arr.length; i++) {
      const el = arr[i];
      if (el.warningResync) return false;
      if (InvoicedSiigo.Unbill === el.state || InvoicedSiigo.SiigoError === el.state) {
        continue;
      } else return false;
    }
    return true;
  }, [selected]);

  const enableSendDian = useMemo(() => {
    const arr = Object.values(selected);
    if (!arr.length) return false;
    for (let i = 0; i < arr.length; i++) {
      const el = arr[i];
      if (el.warningResync) return false;
      if (InvoicedSiigo.DianAccepted !== el.state && !el.cufe) {
        continue;
      } else return false;
    }
    return true;
  }, [selected]);

  useMount(() => {
    if (filterName.eInvoiced !== getState().name)
      setFilterState({
        name: filterName.eInvoiced,
        filter: {},
      });
    fetchList(requestState, filterState);
  });

  // useEffect(() => {
  //   const obj = {};
  //   mappedData
  //     .filter(({ id }) => selectID.arr.includes(id))
  //     .forEach((item, index) => {
  //       obj[item["id"]] = {
  //         ...item,
  //         index,
  //         checked: true,
  //       };
  //     });
  //   replaceSelected(obj);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mappedData]);

  // const actionSelectOption = useMemo(() => {
  //   return [
  //     {
  //       text: lang.sendToAction,
  //       onClick: () => sendToAction(selectID.arr),
  //     },
  //     {
  //       text: lang.sendToDian,
  //       onClick: () => sendToModal.show({ ids: selectID.arr, toDian: true }),
  //     },
  //   ];
  // }, [selectID.arr, sendToModal, sendToAction]);

  const columns = useMemo(
    () => [
      {
        key: "invoiceNo",
        text: lang.invoiceNumber,
        custom: true,
        align: "left",
      },
      {
        key: "guestName",
        text: lang.guest,
        custom: true,
      },
      {
        key: "issueDate",
        text: lang.issueDate,
        custom: true,
      },
      {
        key: "status",
        text: lang.state,
        custom: true,
      },
      {
        key: "siigoInvNo",
        text: lang.siigoInvNo,
        custom: true,
      },
      {
        key: "cufe",
        text: lang.cufe,
        custom: true,
      },
      {
        key: "action",
        actions: true,
        align: "right",
        actionOptions: [
          {
            text: lang.viewInvoice,
            onClick: ({ id }) => {
              history.push(Path.SIIGO_VIEW_INVOICE(id));
            },
          },
        ],
      },
    ],
    [history]
  );

  const renderData = useMemo(
    () =>
      mappedData.map(
        ({
          id,
          invoiceNo,
          invoiceData,
          guestName,
          issueDate,
          state,
          siigoInvoiceNo,
          cufe,
          errorReason,
          warningResync,
        }) => ({
          id,
          invoiceNo: (
            <div className="flex gap-2">
              {warningResync && (
                <Tooltip
                  title={
                    <div className="" style={{ width: 491, maxWidth: 491 }}>
                      <div className="bg-red-100 text-red-lighter px-4 py-3">
                        <Icon name={"warning"} /> {lang.missingTransactionsDetected}
                      </div>
                      <div className="bg-white px-4 py-3 text-gray-lighter">
                        {lang.warningResyncDesc}
                      </div>
                    </div>
                  }
                  placement="topLeft"
                >
                  <span>
                    <Icon name={"warning"} className={"text-red-lighter"} />
                  </span>
                </Tooltip>
              )}
              <a
                href={Path.SIIGO_VIEW_INVOICE(id)}
                className={classNames(
                  warningResync ? "text-red-lighter" : "text-pelorous",
                  "text-md hover:text-black-light"
                )}
                target="_blank"
                rel="noreferrer"
              >
                {invoiceNo}
              </a>
            </div>
          ),
          guestName: invoiceData?.guestId ? (
            <a
              href={Path.GUEST_DETAILS_ID(invoiceData.guestId)}
              className="text-md text-pelorous hover:text-black-light"
              target="_blank"
              rel="noreferrer"
            >
              {guestName}
            </a>
          ) : (
            <Text
              className="text-pelorous overflow-ellipsis overflow-hidden whitespace-nowrap max-w-r10"
              size="text-md"
            >
              {guestName}
            </Text>
          ),
          issueDate: <Text size="text-md">{moment(issueDate).format("DD/MM/YYYY")}</Text>,
          status: renderState(state, errorReason),
          siigoInvNo: <Text size="text-md">{siigoInvoiceNo}</Text>,
          cufe: renderCufe(cufe),
        })
      ),
    [mappedData]
  );

  return (
    <ModuleWrapper>
      <HeaderA title={lang.eInvoice} description={lang.viewListInvoiceTitle} className="mb-md" />

      <InvoiceFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        applyFilter={applyFilterCb}
        clearFilter={clearFilterCb}
        loading={loading}
        maxLength={220}
      />
      <div className="flex justify-end gap-2 mb-4">
        <Button
          loading={loadingSendSiigo}
          onClick={() => onResync(denormalizeItems)}
          disabled={!enableSync}
        >
          {lang.resyncEInvoice}
        </Button>
        <Button
          loading={loadingSendSiigo}
          onClick={() => sendToAction(denormalizeItems)}
          disabled={!enableSendSiigo}
        >
          {lang.sendToSiigo}
        </Button>
        <Button
          loading={loadingSendSiigo}
          onClick={() => sendToAction(denormalizeItems, true)}
          disabled={!enableSendDian}
        >
          {lang.sendToDian}
        </Button>
        {/* <Dropdown trigger="click" options={actionSelectOption}>
          <div className="cursor-pointer border rounded border-solid border-white-darker flex align-center bg-pelorous justify-center p-sm w-24 text-white">
            <div className="text-base mr-1">{lang.sendTo}</div>
            <Icon name="sort-descending1" className="text-white text-xxs" />
          </div>
        </Dropdown> */}
      </div>
      <DataTable
        resultContent={`${result?.total} ${pluralize(
          result?.total,
          lang.searchResult,
          lang.searchResults
        )}`}
        page={filterState.page}
        pageSize={filterState.pageSize}
        total={result?.total || 0}
        loading={loading}
        error={error}
        selected={selected}
        setSelected={onSelect}
        isAllSelected={isAllSelected}
        setSelectAll={onSelectAll}
        onChangePage={modifyFilters}
        fetchList={fetchList}
        data={renderData}
        columns={columns}
        hasAppliedFilter={!!result?.total}
      />

      <SendToModal {...sendToModal} />
    </ModuleWrapper>
  );
};

const getInitFilterState = (venueId) => {
  return {
    venueId,
    searchKey: "",
    state: "",
    page: 1,
    pageSize: 20,
    dateRange: [moment("00:00:00", "HH:mm:ss"), moment("23:59:00", "HH:mm:ss")],
  };
};

const renderState = (state, errorReason = []) => {
  const text = prettifyInvoiced(state);
  let color = "bg-f3f2f1 text-gray border-gray";
  let icon;
  let before;

  switch (state) {
    case InvoicedSiigo.Unbill:
      color = "bg-f3f2f1 text-gray border-gray";
      break;
    case InvoicedSiigo.SiigoAccepted:
      color = "bg-blue-lightest text-blue border-blue";
      break;
    case InvoicedSiigo.DianAccepted:
      color = "bg-green-lighter text-green-darker border-green-darker";
      break;
    case InvoicedSiigo.DianError:
      before = <div className="w-1 h-5 bg-red-lighter"></div>;
      color = "bg-faebed text-red-lighter border-red-lighter";
      icon = (
        <Tooltip
          title={
            <div className="" style={{ width: 491, maxWidth: 491 }}>
              <div className="bg-red-100 text-red-lighter px-4 py-3">
                <Icon name={"warning"} /> {lang.error}
              </div>
              <div className="bg-white px-4 py-3">
                {errorReason.map((msg) => (
                  <Text size="text-md">{msg}</Text>
                ))}
              </div>
            </div>
          }
          placement="topLeft"
        >
          <span>
            <Icon name={"warning"} />
          </span>
        </Tooltip>
      );
      break;
    default:
      color = "bg-faebed text-red-lighter border-red-lighter";
      icon = (
        <Tooltip
          title={
            <div className="" style={{ width: 400, maxWidth: 500 }}>
              <div className="bg-red-100 text-red-lighter px-4 py-3">
                <Icon name={"warning"} /> {lang.error}
              </div>
              <div className="bg-white px-4 py-3">
                {errorReason.map((msg) => (
                  <Text size="text-md" key={uuidv4()}>
                    {msg}
                  </Text>
                ))}
              </div>
            </div>
          }
          placement="topLeft"
          overlayClassName={styles["ant-tooltip"]}
          color="white"
        >
          <span>
            <Icon name={"warning"} />
          </span>
        </Tooltip>
      );
  }

  return (
    <div
      className={classNames(
        "h-10 border border-solid inline-flex justify-center items-center gap-2 px-3 rounded-lg text-md whitespace-nowrap",
        color
      )}
    >
      {before}
      {text}
      {icon}
    </div>
  );
};

const renderCufe = (value) => {
  if (!value) return "-";
  return (
    <div className="flex items-center">
      <Text className="text-md max-w-32 whitespace-nowrap overflow-ellipsis overflow-hidden">
        {value}
      </Text>

      <div
        className="w-8 h-8 bg-gray-lightest flex justify-center items-center rounded-full cursor-pointer"
        onClick={() => {
          navigator.clipboard.writeText(value);
          Toast({
            content: lang.copiedCUFEnumber,
            success: true,
            icon: "check",
          }).open();
        }}
      >
        <CopyIcon />
      </div>
    </div>
  );
};

export default InvoicesModule;
