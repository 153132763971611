import React, { useCallback, useMemo, useContext } from "react";
import lang from "translations";
import { Path } from "paths";
import { HeaderB } from "components/headers";
import { FragmentA, ModuleWrapper } from "components/fragments";
import { VenueContext } from "contexts";
import { editPaymentGatewaySetting, getPaymentGatewaySetting } from "apis/payment-gateway.api";
import { useApi, useModal, useMount, useRouter } from "hooks";
import { Text, Toast } from "components/commons";
import { mixpanel, TrackEvent } from "mixpanel";
import { Form, Field, Checkbox } from "components/commons/index";
import { ActionButton, Panel, Skeleton } from "components/index";
import initialFormState from "../form/payment-gatway-setting.form-state";
import useForm from "hooks/useForm";
import Input from "components/commons/input/input";
import DeletePaymentGatewaySetting from "../delete-payment-gateway-modal/delete-payment-gateway-modal";

const PaymentGatewaySettingEdit = () => {
  const { query, history } = useRouter();
  const { id } = query;

  const deleteModal = useModal();
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const {
    request: getPaymentGatewaySettingDetails,
    loading = true,
    mappedData,
  } = useApi({
    api: getPaymentGatewaySetting,
    mapper: {
      _keys: ["id", "merchantId", "displayName", "isDefault", "merchantSecret", "merchantUrl"],
    },
    params: {
      venueId,
    },
  });

  const unsaveChangesModal = useModal();
  useMount(() => {
    fetchDetails();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.editCharge,
    });
  });

  const fetchDetails = useCallback(async () => {
    await getPaymentGatewaySettingDetails({ id });
  }, [getPaymentGatewaySettingDetails, id]);

  const initialState = useMemo(() => {
    const { displayName, merchantUrl, merchantId, merchantSecret, isDefault } = mappedData;
    return {
      displayName,
      merchantUrl,
      merchantId,
      merchantSecret,
      isDefault,
    };
  }, [mappedData]);

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { fields, modifyField, getFormValues, dirty, submitForm } = useForm({
    initialState: formState,
  });

  const goToList = useCallback(() => {
    history.push(Path.PAYMENT_GATEWAY_SETTING);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  //submit api

  const { request: editPaymentGatewaySettingRequest, loading: submitting } = useApi({
    api: editPaymentGatewaySetting,
    handleOwnError: {
      badrequest: true,
    },
  });

  const submitUpdate = useCallback(async () => {
    try {
      await editPaymentGatewaySettingRequest({
        ...getFormValues(),
        id,
        venueId: venueId,
      });
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
    } catch (error) {
      console.log(error);
    }
  }, [getFormValues, editPaymentGatewaySettingRequest, id, venueId]);

  return (
    <ModuleWrapper
      header={
        <HeaderB
          returnText={lang.paymentsSettings}
          title={lang.editPaymentGateway}
          returnPath={Path.PAYMENT_GATEWAY_SETTING}
          onClick={() => {}}
        />
      }
    >
      <Form
        unsaveChangesModal={unsaveChangesModal}
        isPrompt={dirty}
        submitForm={() => submitForm(submitUpdate)}
      >
        <FragmentA>
          <Panel>
            {loading ? (
              <Skeleton />
            ) : (
              <div>
                <Field label={lang.displayName} {...fields.displayName}>
                  <Input required {...fields.displayName} onChange={modifyField} />
                </Field>
                <Field label={lang.merchantUrl} {...fields.merchantUrl}>
                  <Input required {...fields.merchantUrl} onChange={modifyField} />
                </Field>
                <Field label={lang.merchantId} {...fields.merchantId}>
                  <Input required {...fields.merchantId} onChange={modifyField} />
                </Field>
                <Field
                  label={lang.merchantSecret}
                  {...fields.merchantSecret}
                  autocomplete="new-password"
                >
                  <Input
                    required
                    inputType="password"
                    {...fields.merchantSecret}
                    onChange={modifyField}
                  />
                </Field>
                <div className="flex flex-row">
                  <Checkbox
                    {...fields.isDefault}
                    // className={classnames("m-auto")}
                    onChange={(name, obj) => {
                      modifyField("isDefault", { value: obj.value });
                    }}
                  />

                  <div>
                    <Text className="ml-sm">{lang.isDefaultPaymentGateway}</Text>
                  </div>
                </div>
              </div>
            )}
          </Panel>
        </FragmentA>

        <ActionButton
          showLine
          loading={submitting}
          primary={{
            disabled: fields.locationIds?.length === 0 && !fields.isActiveAllLocations,
            onClick: () => {
              submitForm(submitUpdate);
            },
          }}
          secondary={{
            text: lang.cancel,
            onClick: () => leavePage(),
          }}
          danger={{
            text: lang.deletePaymentGateway,
            onClick: () => deleteModal.show(),
          }}
        />
        <DeletePaymentGatewaySetting id={id} {...deleteModal} />
      </Form>
    </ModuleWrapper>
  );
};

export default PaymentGatewaySettingEdit;
