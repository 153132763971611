import React, { useCallback, useMemo, useContext } from "react";
import lang from "translations";
import { Path } from "paths";
import { HeaderB } from "components/headers";
import { FragmentA, ModuleWrapper } from "components/fragments";
import { VenueContext } from "contexts";
import { createPaymentGateway } from "apis/payment-gateway.api";
import { useApi, useModal, useMount, useRouter } from "hooks";
import initialFormState from "../form/payment-gatway-setting.form-state";
import { Text, Toast } from "components/commons";
import { mixpanel, TrackEvent } from "mixpanel";
import { Form, Field, Checkbox } from "components/commons/index";
import { ActionButton, Panel } from "components/index";
import useForm from "hooks/useForm";
import Input from "components/commons/input/input";

const PaymentGatewaySetting = () => {
  const { history } = useRouter();

  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const unsaveChangesModal = useModal();
  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.addCharge,
    });
  });

  const initialState = useMemo(() => {
    return {
      id: null,
      displayName: "",
      merchantUrl: "",
      merchantId: "",
      merchantSecret: "",
      isDefault: false,
      venueId: venueId,
    };
  }, [venueId]);

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { fields, modifyField, getFormValues, dirty, submitForm } = useForm({
    initialState: formState,
  });

  const goToList = useCallback(() => {
    history.push(Path.PAYMENT_GATEWAY_SETTING);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  //submit api

  const { request: createPaymentGatewayRequest, loading: submitting } = useApi({
    api: createPaymentGateway,
    handleOwnError: {
      badrequest: true,
    },
  });

  const submitAdd = useCallback(async () => {
    try {
      await createPaymentGatewayRequest({
        ...getFormValues(),
        venueId: venueId,
      });
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      goToList();
    } catch (error) {
      console.log(error);
    }
  }, [createPaymentGatewayRequest, venueId, getFormValues, goToList]);

  return (
    <ModuleWrapper
      header={
        <HeaderB
          returnText={lang.paymentsSettings}
          title={lang.addPaymentGateway}
          returnPath={Path.PAYMENT_GATEWAY_SETTING}
          onClick={() => {}}
        />
      }
    >
      <Form
        unsaveChangesModal={unsaveChangesModal}
        isPrompt={dirty}
        submitForm={() => submitForm(submitAdd)}
        autocomplete="off"
      >
        <FragmentA>
          <Panel>
            <div>
              <Field label={lang.displayName} {...fields.displayName}>
                <Input required {...fields.displayName} onChange={modifyField} />
              </Field>
              <Field label={lang.merchantUrl} {...fields.merchantUrl}>
                <Input required {...fields.merchantUrl} onChange={modifyField} />
              </Field>
              <Field label={lang.merchantId} {...fields.merchantId}>
                <Input required {...fields.merchantId} onChange={modifyField} />
              </Field>
              <Field
                label={lang.merchantSecret}
                {...fields.merchantSecret}
                autocomplete="new-password"
              >
                <Input
                  required
                  inputType="password"
                  {...fields.merchantSecret}
                  onChange={modifyField}
                />
              </Field>
              <div className="flex flex-row">
                <Checkbox
                  {...fields.isDefault}
                  // className={classnames("m-auto")}
                  onChange={(name, obj) => {
                    modifyField("isDefault", { value: obj.value });
                  }}
                />

                <div>
                  <Text className="ml-sm">{lang.isDefaultPaymentGateway}</Text>
                </div>
              </div>
            </div>
          </Panel>
        </FragmentA>

        <ActionButton
          showLine
          loading={submitting}
          primary={{
            disabled: fields.locationIds?.length === 0 && !fields.isActiveAllLocations,
            onClick: () => {
              submitForm(submitAdd);
            },
          }}
          secondary={{
            text: lang.cancel,
            onClick: () => leavePage(),
          }}
        />
      </Form>
    </ModuleWrapper>
  );
};

export default PaymentGatewaySetting;
