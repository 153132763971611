import { Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const {
    displayName = "",
    merchantUrl = "",
    merchantId = "",
    merchantSecret = "",
    isDefault,
    venueId,
  } = initialState;
  return {
    displayName: {
      name: "displayName",
      value: displayName,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
    },
    merchantUrl: {
      name: "merchantUrl",
      value: merchantUrl,
      type: Field.INPUT,
      validations: [Validation.required(), Validation.url()],
      required: true,
    },
    merchantId: {
      name: "merchantId",
      value: merchantId,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
    },
    merchantSecret: {
      name: "merchantSecret",
      value: merchantSecret,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
    },
    isDefault: {
      name: "isDefault",
      value: isDefault,
      type: Field.CHECKBOX,
      title: lang.isDefaultPaymentGateway,
    },
    venueId: {
      name: "venueId",
      value: venueId,
      type: Field.INPUT,
      required: true,
    },
  };
};

export default initialFormState;
